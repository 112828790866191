.work_in_progress {
    width:20%;
    display:flex;
    flex-wrap: wrap;
    align-content: flex-start;
}

.work_in_progress .loading {
    width: 100%;
    text-align: center;
}


.work_in_progress .wip_img_wrapper {
    display: flex;
    width:50%;
    /* flex: 50%; */
    justify-content: center;
    flex-wrap: wrap;
    position:relative;
}

.work_in_progress .wip_img_wrapper img {
    width:90%;
    cursor:pointer;
    border: 5px solid transparent;
}

.work_in_progress .wip_img_wrapper img.selected {
    border: 5px solid rgb(25, 118, 210);
}

.image_position {
    position: absolute;
    right: 5%;
    bottom: 10%;
    background-color: black;
    color: white;
    padding: 5px 10px;
    border-radius: 50px;
    font-weight: bold;
    border: 5px solid rgb(25, 118, 210);
}

.image_original {
    position: absolute;
    left: 10%;
    bottom: 3%;
}

@media (max-width: 1400px) and (min-width:1025px) {
}

@media (max-width: 1024px) {

    .work_in_progress {
        width: 100%;
        flex-wrap: wrap;
        /* align-content: flex-start; */
        justify-content: center;
        display: none;
    }

    .work_in_progress .wip_img_wrapper { 
        display: flex;
        width: 25%;
        justify-content: center;
        flex-wrap: wrap;
        position: relative;
    }

    .status_bar { 
        margin: 0;
        padding: 0px; 
        display:flex; 
        width:auto;
        justify-content: center; 
    }
}

