.menu {
    width: 20%;
}

@media (max-width: 1400px) and (min-width:1025px) {
}

@media (max-width: 1024px) {

    .menu {
        width:90%;
        padding:2% 5% 8%;
        justify-content: center;
    }

}