.image_viewer { 
    width:60%;
    padding-right:20px;
    display:flex;
    justify-content: center;
}

.image_wrapper {
    height:800px;
    width:800px;
    border: 4px dashed #ccc;
}

.image_viewer img {
    width:800px;
}

@media (max-width: 1400px) and (min-width:1025px) {
}

@media (max-width: 1024px) {

    .image_viewer { 
        width:100%;
        padding-right:0px;
    }

    .image_wrapper {
        height:auto;
        width:100%;
        border: 0 none;
    }

    .image_viewer img {
        width:100%;
    }

}