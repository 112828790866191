.footer { 
    position:fixed; 
    padding:10px; 
    bottom:0px;
    right:0px;
    color:white;
    background-color: #272E44;
    border-radius:10px 0 0 0;
}

.footer img {
    height:15px;
}
