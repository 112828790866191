.header_wrapper { 
    position: relative; 
    display:flex;
}

.header_logged_logo { 
    position:absolute; 
    left:50%; 
    transform: translate(-50%, 0); 
    margin-top:10px; 
    margin-bottom:10px; 
}

.header_logged_logo a img { 
    max-width:200px; 
}

.status_bar { 
    margin-left:auto;
    padding: 15px; 
    display:flex; 
    width:300px;
    justify-content: space-between; 
}

.status_section { 
    display: flex; 
    align-items: center; 
    flex-wrap: wrap; 
    padding:10px; 
}

.button_like { 
    cursor:pointer; 
    border-radius:5px; 
    transition: all 0.5s ease; 
}

.button_like:hover { 
    background-color: #ddd; 
}

.mobile {
    display:none;
}

@media (max-width: 1400px) and (min-width:1025px) {
}

@media (max-width: 1024px) {

    .mobile {
        display:flex;
    }

    .header_wrapper {
        flex-direction: column;
    }

    .header_logged_logo { 
        position:initial; 
        transform: none; 
        margin-top:10px; 
        margin-bottom:0px;
        text-align: center;
    }

    .status_bar { 
        margin: 0 0 10px 0;
        padding: 0px; 
        display:flex; 
        width:auto;
        justify-content: center; 
    }
}