.main_block {
    padding:20px;
    display:flex;
    justify-content: space-between;
}

@media (max-width: 1400px) and (min-width:1025px) {
}

@media (max-width: 1024px) {

    .main_block {
        flex-direction: column;
        padding:0px;
    }

}